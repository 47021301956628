.myTypeLayerLayout {
  width: 232px;
  border: 1px solid #333;
  border-top: 0px;
  background: #1a1e28;
  padding-bottom: 10px;

  > .layerList {
    height: calc(100% - 127px);
    overflow: auto;

    .listButton {
      width: 190px;
      height: 30px;
      margin: 2px auto;
      background-color: #232732;
      border-radius: 6px;
      display: flex;
      cursor: pointer;

      > .text {
        font-size: 12px;
        color: #eeeeee;
        margin: auto auto auto 3px;
      }

      > .CheckIcon {
        width: 20px;
        height: 20px;
        color: #555;
        margin: auto 0px auto 10px;

        &.close {
          display: none;
        }

        &.selected {
          color: #95e4b3;
        }
      }

      .error {
        color: #e81c1c;
      }

      &.selected {
        background-color: #60626a;
      }

      &.selected:hover {
        background-color: #60626a;
        .close {
          display: inline;
        }
        .check {
          display: none;
        }
      }
    }

    .error {
      color: #e81c1c;
    }

    .listButton:hover {
      background-color: #60626a;
    }
  }

  > .arrowDiv {
    display: flex;
    align-items: flex-end;
    flex: 0 0 auto;
    width: 190px;
    height: 20px;
    margin: 20px auto 15px auto;

    > .text {
      position: relative;
      margin-right: auto;
      font-size: 12px;
      color: #999;
    }

    > .closeIcon {
      position: relative;
      color: #555;
      margin-left: auto;
      width: 18px;
      height: 18px;
      cursor: pointer;
    }
  }

  > .switchTabs {
    width: 190px;
    height: 30px;
    display: flex;
    background-color: #383b45;
    border-radius: 5px;
    margin: 0px auto 20px auto;
    font-family: Noto Sans KR;

    .switchButton {
      width: 92px;
      height: 26px;
      padding: 0px;
      margin: 2px;
      border-radius: 6px;
      display: flex;
      color: #cccccc;
      cursor: pointer;

      .text {
        font-size: 12px;
        margin: auto;
      }

      &.enable {
        background-color: #eeeeee;
        color: #232732;
      }
    }
    .switchButton:hover {
      background-color: #eeeeee;
      color: #232732;
    }
  }

  > .description {
    font-size: 12px;
    color: #48ddf2;
    margin: 0 22.5px 10px 22.5px;

    &.hidden {
      display: none;
    }
  }
}
