.errorLog {
  background-color: #1a1e28;
  height: 281px;
  > .errorLogHead {
    border: 1px solid #333;
    border-right: 0px;
    display: flex;
    > .headText {
      font-size: 12px;
      color: #999;
      margin: auto;
      margin-left: 10px;
    }
    > .icon {
      width: 20px;
      height: 20px;
      color: #555;
      cursor: pointer;
      margin-right: 10px;
    }
  }

  .errorLogBody {
    display: flex;
    height: inherit;

    > .category {
      width: 160px;
      height: 254px;
      border: 1px solid #333;
      border-top: 0px;

      > .cell {
        height: 29px;
        display: flex;
        color: #ccc;
        border-bottom: 1px solid #333;
        cursor: pointer;

        > .icon {
          width: 16px;
          height: 16px;
          margin: auto 2px auto 10px;

          &.red {
            color: #e81c1c;
          }
          &.yellow {
            color: #ffb068;
          }
        }
        > .text {
          font-size: 14px;
          margin: auto 0;
        }

        &.active {
          background: #60626a;
        }

        &.hidden {
          display: none;
        }
      }
      > .cell:hover {
        background: #60626a;
      }
    }

    > .errorList {
      width: 100%;
      height: 255px;
      overflow: auto;

      > .info {
        cursor: pointer;
        padding: 5px 0;
        height: fit-content;
        display: flex;
        border-bottom: 1px solid #333;
        // border-right: 1px solid #333;

        > .icon {
          width: 16px;
          height: 16px;
          margin: 3px 10px auto 20px;

          &.red {
            color: #e81c1c;
          }
          &.yellow {
            color: #ffb068;
          }
        }
        .text {
          font-size: 14px;
          margin: 1px;
          color: #ccc;

          &.sub {
            font-size: 12px;
          }
        }
      }
      .info:hover {
        &.error {
          background: rgba(232, 28, 28, 0.2);
        }
        &.warning {
          background-color: rgba(255, 176, 104, 0.2);
        }
      }
    }
  }
}
