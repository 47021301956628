.loadingPage {
  width: 100%;
  height: 100%;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  text-align: center;
  z-index: 1;
  background: #000000;
  opacity: 0.7;
  display: flex;
  flex-direction: column;

  .progress {
    position: relative;
    margin: auto auto 0 auto;
    height: 244px;
    animation: rotation 1.33s infinite linear;
  }
  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }

  .centerWord {
    transform: translate(-50%, -100%);
    top: 50%;
    left: 50%;
    position: absolute;
    width: 197px;
    font-family: Noto Sans KR;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    color: #eeeeee;
  }

  .loadingMessage {
    margin: 30px auto auto auto;
    font-family: Noto Sans KR;
    font-style: normal;
    font-size: 18px;
    line-height: 26px;
    text-align: center;
    color: #48ddf2;
    > .white {
      color: white;
    }
  }
}
